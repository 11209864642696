.footer {
  background: #fcb90c;
  height: 136px;
  color: white;
  font-family: "Barlow", sans-serif;
  position: relative;

  img {
    max-width: 106px;
  }

  p {
    font-weight: 600;
    font-size: 14px;

    line-height: 16px;
    letter-spacing: 1px;
    font-family: "Barlow", sans-serif;
  }

  .decoration-left {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .decoration-right {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
