@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&family=Barlow:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.menu-page {
  padding-top: 50px;
  background-image: url("./resources/img/main-background.png");
}

.app {
  display: block;
}

.only-mobile {
  display: block;
  background-color: #fcb90c;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center the children */
  align-items: center; /* Vertically center the children */
  height: 100%;

  font-family: "Barlow Condensed";
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;

  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .app {
    display: inherit;
  }

  .only-mobile {
    display: none;
  }
}
