.category {
    background-color: #00000061;
    height: 128px;
    color: white;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    p {
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-family: 'Barlow', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.1px;
        width: calc(100% - 20px);
    }

    .category-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        background: linear-gradient(180deg, rgba(0,0,0,0.0), rgba(0,0,0,0.56));
    }
}