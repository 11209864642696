.dish-page {

    .description,
    .choose,
    .options {
        font-family: 'Barlow';
        font-weight: 200;
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 18px;
    }

    .choose,
    .options {
        padding-top: 12px;

        hr{
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 5px;
            font-weight: bold;
        }
    }

    .options {
        font-weight: 400;
    }
}