.splash-bg {
  // background-image: url("/resources/img/splash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  height: 100%;
  width: 100%;
}

.overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 55%, #fcb90c);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: white;

  h1 {
    font-family: "Barlow";
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
  }

  h2 {
    font-family: "Barlow";
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
  }
}
