.craving-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    p {
        color: white;
        font-family: 'Barlow', sans-serif;
        font-weight: 600;
        font-size: 18px;
        width: 60%;
        text-align: right;
        position: absolute;
        right: 20px;
        top: 18px;
        line-height: 20px;
    }

    button {
        border: solid 1px white;
        color: white;
        padding: 4px 14px 4px 14px;
        border-radius: 100px;
        font-family: 'Barlow', sans-serif;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
}

.most-requested {
    height: 185px;
    width: 100%;
    background-color: black;
    color: white;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    background-image: url('/resources/img/most-requested.jpg'); /* Update with the actual path to your GIF */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:scroll ;
    background-position: center;

    button {
        background: #FCB90C;
        color: white;
        width: 84px;
        line-height: 14px;
        padding: 4px 14px 6px 14px;
        border-radius: 100px;
        font-family: 'Barlow', sans-serif;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        bottom: 20px;
    }
}