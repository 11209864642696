.addons-page {
    .sub-header {
        background-color: #FCB90C;
        color: #393939;
        padding: 5px 60px 12px 60px;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-family: 'Barlow';
        letter-spacing: -0.2px;
    }

    .extra,
    .label {
        color: #393939;
        font-family: 'Barlow';
        font-size: 15.5px;
        line-height: 20px;
    }

    .extra {
        line-height: 16px;
    }
}