.header {
  background: #fcb90c;
  color: white;
  width: 100%;
  height: 55px;
  position: fixed;
  top: 0;
  text-align: center;
  display: flex;
  z-index: 10;

  & img {
    max-width: 107px;
    margin-top: 0px;
  }

  h1 {
    font-family: "Barlow Condensed";
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    color: #393939;
  }

  .logo {
    label {
      font-size: 10px;
      margin-top: 1px;
      display: block;
    }
  }
}

.header .decoration-left {
  position: absolute;
  top: 15px;
  left: 10px;

  & img[alt="Back"] {
    margin-top: -10px;
    margin-left: -10px;
    padding: 10px;
  }
}

.header .decoration-right {
  position: absolute;
  top: 15px;
  right: 10px;

  & img[alt="Home"] {
    margin-top: -10px;
    margin-right: -10px;
    padding: 10px;
  }
}
